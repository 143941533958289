@use "/src/variables";

@keyframes openInput {
  0% {
    opacity: 0;
    margin-top: 10rem;
  }
  100% {
    opacity: 1;
    margin-top: 0rem;
  }
}

@keyframes openInputMobile {
  0% {
    opacity: 0;
    margin-top: 10rem;
  }
  100% {
    opacity: 1;
    margin-top: 2rem;
  }
}

@keyframes openArrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes openError {
  0% {
    opacity: 0;
    top: 250%;
  }
  100% {
    opacity: 1;
    top: 240%;
  }
}

@keyframes openErrorMobile {
  0% {
    opacity: 0;
    top: 190%;
  }
  100% {
    opacity: 1;
    top: 170%;
  }
}

.SignUp {
  margin-right: 1rem;

  &__welcome {
    width: 90%;
    position: absolute;
    top: 25%;
    word-wrap: break-word;

    h2 {
      align-self: flex-start;
      font-size: 3rem;
    }

    h3 {
      align-self: flex-start;
      margin: 1rem 0;
      font-weight: normal;
      color: variables.$attenuated_color;
    }
  }

  &__inputs {
    position: relative;

    @media screen and (max-width: 900px) {
      margin-top: 2rem;
    }

    @media screen and (max-width: 374px) {
      max-width: 280px;
    }

    p {
      font-size: 1.5rem;
      padding: 0.5rem 0rem;
      margin-right: 0.5rem;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      animation: openInput 0.6s;
      overflow: hidden;

      @media screen and (max-width: 900px) {
        animation: openInputMobile 0.6s;
      }
    }

    &__nav {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      display: flex;
      justify-content: center;

      i {
        font-size: 2rem;
        color: variables.$focus_color;
        cursor: pointer;
        padding: 1rem;
        animation: openArrow 0.4s;
      }
    }

    input {
      display: inline;
      flex: 1;
      width: fit-content;
      padding: 0.5rem 0.5rem;
      margin-left: 1rem;
    }
  }

  button {
    width: 100%;
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, 0%);

    @media screen and (max-width: 900px) {
      top: 140%;
      width: 100%;
    }
  }

  &__error {
    color: red;
    font-style: italic;
    position: absolute;
    top: 270%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, 0%);
    font-size: 1.2rem !important;
    animation: openError 0.4s !important;

    @media screen and (max-width: 900px) {
      top: 170%;
      animation: openErrorMobile 0.4s !important;
    }
  }

  &__link {
    position: absolute;
    bottom: 5rem;
    color: variables.$attenuated_color;

    @media screen and (max-width: 900px) {
      bottom: 2rem;
    }

    strong {
      color: variables.$focus_color;
    }
  }
}
