@use "/src/variables";

.CloseModale {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: variables.$base_border;
  border-radius: 50%;
  align-self: flex-end;
  cursor: pointer;

  > i {
    font-size: 2rem;
  }
}
