@use "../../variables";

.Panel {
  height: 100vh;
  max-height: 100vh;
  min-width: 360px;
  width: 25%;
  max-width: 360px;
  background-color: variables.$overlay_background;
  padding: 0rem 1.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: variables.$base_shadow;

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.Panel__userInfos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  .Panel__userInfos--left {
    display: flex;

    img {
      width: 62px;
      height: 62px;
      object-fit: cover;
      border-radius: 50%;
    }

    p {
      margin-left: 1rem;
      font-size: 1.4rem;
    }
  }

  @keyframes openUserMenu {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 850%;
    }
  }

  .Panel__userInfos--right {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;

    &:hover {
      ul {
        animation: openUserMenu 0.4s forwards;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: variables.$base_border;
      border-radius: 50%;
      width: 40px;
      height: 40px;

      i {
        font-size: 1.4rem;
        color: variables.$attenuated_color;
      }
    }

    ul {
      top: 2.6rem;
      right: 0;
      position: absolute;
      background-color: variables.$overlay_background;
      box-shadow: 0 4px 10px rgb(0, 0, 0, 0.1);
      border-radius: 15px;
      max-height: 0;
      overflow: hidden;

      p {
        padding: 1rem 2rem;

        &:first-child {
          color: variables.$attenuated_color;
          white-space: nowrap;
          font-style: italic;
          padding: 1rem;
          margin-top: 1rem;
          margin-right: 4rem;
        }

        &:not(:first-child) {
          padding-left: 3rem;
          padding-right: 2rem;
        }

        &:last-child {
          margin-bottom: 1rem;
        }
      }

      i {
        color: #74c77a;
        margin-right: 1rem;
      }
    }
  }
}

/* Calendar */

.sdp {
  box-shadow: none !important;
  width: 100% !important;
  margin: auto;
}

.sdp--square-btn {
  box-shadow: none !important;
  color: variables.$base_color !important;

  > svg {
    overflow: visible;
  }

  &[tabindex="-1"] {
    color: #bdbdbd !important;
  }
}

.sdp--date-btn__selected {
  background-color: variables.$focus_color !important;
  color: #fff !important;
}

/* RESPONSIVE */
