@use "/src/variables";

.Settings {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  padding-top: 7rem;
  max-height: 100vh;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 900px) {
    overflow-x: initial;
    padding: 0 1rem;
    padding-top: 6.5rem;
    min-height: fit-content;
    max-height: none;
  }

  &__container {
    background-color: variables.$overlay_background;
    border-radius: 20px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @media screen and (max-width: 900px) {
      margin-bottom: 2rem;
    }
  }

  h1 {
    font-size: 1.3rem;
  }

  &__profil_picture {
    margin-top: 2rem;
    width: 140px;
    height: 140px;
    align-self: center;
    position: relative;

    input[type="file"] {
      appearance: none;
      display: none;
    }

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__icon {
      background-color: variables.$focus_color;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer;
    }
  }

  &__infos {
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: variables.$base_border;

    h2 {
      font-size: 1.2rem;
      margin-bottom: 0.2rem;
    }

    p {
      font-size: 1rem;
      color: variables.$attenuated_color;
    }
  }

  &__inputs {
    padding-top: 2rem;
    display: flex;

    @media screen and (max-width: 900px) {
      justify-content: space-between;
    }

    p {
      font-weight: bold;
      width: 28%;
      font-size: 0.9rem;

      @media screen and (max-width: 900px) {
        width: 40%;
      }
    }

    input {
      margin-top: 0rem;
      padding: 0.5rem;
      font-size: 1rem;
      border: variables.$base_border;
      border-radius: 10px;
      background-color: #fff;
      width: 50%;

      @media screen and (max-width: 900px) {
        width: 60%;
      }
    }

    &--border {
      border-top: variables.$base_border;
      margin-top: 2rem;
    }
  }

  @keyframes popUp {
    0% {
      margin-top: 1rem;
    }
    100% {
      margin-top: 0rem;
    }
  }

  &__button {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    > p {
      font-size: 0.8rem;
      align-self: center;
      font-style: italic;
      color: red;
    }

    button {
      margin-top: 0rem;
      font-size: 0.8rem;
    }
  }

  &__nav {
    display: flex;
    border-bottom: variables.$base_border;
    padding: 0.5rem 0;
    color: variables.$attenuated_color;

    @media screen and (max-width: 900px) {
      font-size: 0.9rem;
      justify-content: space-between;
    }

    p {
      font-weight: bold;
      position: relative;

      cursor: pointer;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    &--focus {
      color: variables.$base_color;

      &::after {
        content: " ";
        background-color: variables.$base_color;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        position: absolute;
        bottom: -0.55rem;
        left: 0;
        box-shadow: variables.$base_shadow;
      }
    }
  }
}
