@use "/src/variables";

@keyframes openSignIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SignIn {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: openSignIn 0.4s;

  h2 {
    padding-left: 1rem;
    align-self: flex-start;
    font-size: 3rem;

    @media screen and (max-width: 900px) {
      font-size: 2rem;
      padding: 0;
    }
  }

  h3 {
    align-self: flex-start;
    padding-left: 1rem;
    margin: 1rem 0;
    font-weight: normal;
    color: variables.$attenuated_color;

    @media screen and (max-width: 900px) {
      padding: 0;
    }
  }

  input:nth-child(2) {
    margin-top: 1rem;
  }

  button {
    margin-top: 4rem;
    width: 100%;
  }

  > p {
    margin-top: 1rem;
    color: red;
    font-style: italic;
  }

  &__link {
    margin-top: 2rem;
    color: variables.$attenuated_color;

    strong {
      color: variables.$focus_color;
    }
  }
}
