@use "./variables";

/* Generales Roules */

body {
  font-family: "Nunito", sans-serif;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  background-color: variables.$base_background;
  color: variables.$base_color;
  box-sizing: border-box;
}

.App {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 0 2rem;

  @media screen and (max-width: 900px) {
    padding: 0 1rem;
  }
}

.widget {
  padding: 1rem;
  background-color: variables.$overlay_background;
  border-radius: 15px;
}
