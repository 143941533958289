@use "/src/variables";

@keyframes openBlur {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(2px);
  }
}

.Alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
  animation: 2s openBlur forwards;
  z-index: 2;

  &__container {
    position: absolute;
    width: 30%;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: variables.$overlay_background;
    box-shadow: 0 5px 30px rgb(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 900px) {
      box-sizing: border-box;
      width: 95%;
      box-shadow: 0 5px 30px rgb(0, 0, 0, 0.1);
    }

    > i {
      position: absolute;
      font-size: 1.5rem;
      top: 1.5rem;
      right: 1.5rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: bold;

      &:nth-child(2) {
        font-weight: normal;
        margin-top: 0.5rem;
        font-size: 1rem;
        color: variables.$attenuated_color;
      }
    }
  }

  &__btns {
    margin-top: 2rem;
    align-self: flex-end;

    button {
      margin: 0 1rem;
      padding: 0.3rem 2rem;
      border-radius: 15px;
    }
  }
}
