@use "/src/variables";

@keyframes openAnimationTask {
  0% {
    top: -50%;
  }
  100% {
    top: 50%;
  }
}

@keyframes openAnimationMobile {
  0% {
    top: -100vh;
  }
  100% {
    top: 0;
  }
}

.TaskForm {
  position: fixed;
  min-width: fit-content;
  width: 30%;
  top: 50%;
  left: 50%;
  padding: 2rem;
  transform: translate(-55%, -50%);
  border-radius: 25px;
  background-color: variables.$overlay_background;
  box-shadow: 0 5px 100px rgb(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: openAnimationTask 0.3s;

  @media screen and (max-width: 900px) {
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    height: -webkit-fill-available;
    top: 0;
    transform: none;
    left: 0;
    border-radius: 0;
    justify-content: space-between;
    animation: openAnimationMobile 0.3s;
  }

  > i {
    cursor: pointer;
    align-self: flex-end;
    font-size: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 900px) {
      height: 100%;
      justify-content: space-between;
    }
  }

  &__inputs {
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  &__categories {
    margin-top: 2rem;

    @media screen and (max-width: 900px) {
      margin-top: 2rem;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;

      select {
        padding: 0.4rem;
        font-size: 1rem;
        border-radius: 10px;
        border: variables.$base_border;
        width: 55%;
        text-align: end;
        color: variables.$base_color;
      }
    }
  }

  &__date {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      margin-right: 1rem;
      font-weight: bold;
    }

    input {
      padding: 0.5rem;
      border: variables.$base_border;
      border-radius: 10px;
      color: variables.$base_color;
      color: grey;
      width: 49%;
      text-align: center;
    }
  }

  &__priorities {
    display: flex;

    @media screen and (max-width: 900px) {
      margin-top: 0rem;
    }
  }

  &__priority {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    input {
      margin-right: 0.3rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }

  button {
    margin-top: 2rem;
    width: 100%;
  }
}
