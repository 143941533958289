$base_color: #2f1856;
$attenuated_color: #988eac;
$focus_color: #5f2cf6;

$base_background: #f8f8fd;
$overlay_background: #fff;

$base_border: 1px #e2daf0 solid;

$base_shadow: 0px 0.4px 0.7px rgba(0, 0, 0, 0.009),
  0.1px 1.1px 1.7px rgba(0, 0, 0, 0.013), 0.2px 2.3px 3.4px rgba(0, 0, 0, 0.017),
  0.4px 4.7px 6.9px rgba(0, 0, 0, 0.021), 1px 13px 19px rgba(0, 0, 0, 0.03);
