@use "/src/variables";

.UserManagement {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 2rem;
  display: flex;

  @media screen and (max-width: 900px) {
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
  }

  &__left {
    box-sizing: border-box;
    padding: 2rem;
    width: 30%;
    height: 100%;
    background: variables.$focus_color;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    border-radius: 25px 0px 0 25px;
    background: linear-gradient(145deg, #662fff, #5628dd);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #fff;

    @media screen and (max-width: 900px) {
      display: none;
    }

    h2 {
      font-weight: bold;
      font-size: 3rem;
    }

    p {
      text-align: center;
      color: #fff;
      font-size: 2rem;
    }

    img {
      width: 100%;
    }
  }

  &__right {
    box-sizing: border-box;
    width: 70%;
    height: 100%;
    background-color: variables.$overlay_background;
    border-radius: 0 25px 25px 0;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      width: 100%;
      border-radius: initial;
      padding: 2rem;
    }

    &__logo {
      display: flex;
      align-items: center;
      font-size: 2rem;

      > div {
        border-radius: 12px;
        background: linear-gradient(145deg, #662fff, #5628dd);
        box-shadow: 9px 9px 18px #d3d3d7, -9px -9px 18px #ffffff;
        width: 60px;
        height: 60px;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #662fff;
      }

      p {
        font-family: "Roboto Mono";
      }
    }

    .SignUp,
    .SignIn {
      height: 60%;
      width: 80%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }

  &__copyright {
    color: variables.$attenuated_color;
    font-size: 0.7rem;
    align-self: flex-end;
  }
}
