@use "/src/variables";

@keyframes load1 {
  0% {
    left: 0px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
  50% {
    left: 50px;
    height: 10px;
    width: 10px;
    filter: blur(2px);
  }
  100% {
    left: 0px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
}

@keyframes load2 {
  0% {
    left: 90px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
  50% {
    left: 50px;
    height: 10px;
    width: 10px;
    filter: blur(2px);
  }
  100% {
    left: 90px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
}

@keyframes load3 {
  0% {
    top: 15px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
  50% {
    top: 50%;
    height: 10px;
    width: 10px;
    filter: blur(2px);
  }
  100% {
    top: 15px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
}

@keyframes load4 {
  0% {
    top: 105px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
  50% {
    top: 50%;
    height: 10px;
    width: 10px;
    filter: blur(2px);
  }
  100% {
    top: 105px;
    height: 30px;
    width: 30px;
    filter: blur(0);
  }
}

@keyframes screen {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

.LoadScreen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    display: flex;
    width: 120px;
    height: 120px;
    animation: 2s infinite screen;
  }

  &__square {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(0, -50%);
    background: linear-gradient(145deg, #a398b8, #89809b);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);

    &--1 {
      top: 50%;
      left: 0;
      animation: load1 2s infinite;
    }

    &--2 {
      top: 50%;
      animation: load2 2s infinite;
    }

    &--3 {
      top: 15px;
      left: 50px;
      animation: load3 2s infinite;
    }

    &--4 {
      top: 105px;
      left: 50px;
      animation: load4 2s infinite;
    }
  }

  &__squareMini {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(0, -50%);

    &--1 {
      top: 50%;
      left: 0;
      animation: load1 2s infinite;
    }
  }
}
