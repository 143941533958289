@use "/src/variables";

@keyframes neonEffectPurple {
  0% {
    box-shadow: 0 2px 4px rgba(95, 44, 246, 0.4);
  }
  50% {
    box-shadow: 0 4px 8px rgba(95, 44, 246, 0.4);
  }
  100% {
    box-shadow: 0 2px 4px rgba(95, 44, 246, 0.4);
  }
}

@keyframes neonEffectRed {
  0% {
    box-shadow: 0 2px 4px rgba(247, 73, 70, 0.4);
  }
  50% {
    box-shadow: 0 4px 8px rgba(247, 73, 70, 0.4);
  }
  100% {
    box-shadow: 0 2px 4px rgba(247, 73, 70, 0.4);
  }
}

@keyframes turn360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.SubmitButton {
  margin-top: 2rem;
  width: fit-content;
  border: none;

  color: #fff;
  padding: 1rem;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;

  &--purple {
    background-color: variables.$focus_color;
    animation: neonEffectPurple 2s infinite;
  }

  &--red {
    background-color: #f74946;
    animation: neonEffectRed 2s infinite;
  }
}

.fa-spinner {
  color: #fff;
  animation: 1s turn360 infinite;
}
