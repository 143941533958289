@use "/src/variables";

@keyframes bar {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Statistics {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  padding-top: 7rem;
  height: 100vh;

  &__container {
    background-color: variables.$overlay_background;
    border-radius: 20px;
    padding: 1rem 2rem;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 5rem;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
      }
    }
  }

  &__bar {
    animation: bar 0.6s infinite linear;
  }
}
