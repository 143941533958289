@use "/src/variables";

.IconPicker {
  margin-top: 2rem;
  padding: 0 1rem;
  width: 100%;
  align-self: flex-start;
  position: relative;

  @media screen and (max-width: 900px) {
    margin-top: 3rem;
    padding-left: 0rem;
  }

  &--input {
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      input {
        min-width: 90%;
      }
    }

    > div {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      @media screen and (max-width: 900px) {
        align-self: center;
        margin-left: 0;
        transform: translate(-50%);
        margin-top: 1rem;
      }

      i {
        color: variables.$base_color;
        transition: 0.3s;
        font-size: 2rem;
        border-radius: 10px;
      }
    }

    > p {
      white-space: nowrap;
      margin-right: 1rem;
      font-weight: bold;

      @media screen and (max-width: 900px) {
        margin-bottom: 1rem;
      }
    }
  }

  &--suggestion {
    align-self: center;
    margin-top: 0.2rem;
    width: 55%;
    max-height: 14rem;
    overflow-y: scroll;
    height: fit-content;
    font-size: 2rem;
    background-color: variables.$base_background;
    border-radius: 15px;
    transition: 0.3s;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    top: 3rem;
    left: 8rem;
    transition: 0.3s;
    z-index: 2;

    @media screen and (max-width: 900px) {
      top: 5rem;
      left: 1rem;
      width: 80%;
      max-height: 11rem;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      padding: 2rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 15px;
      cursor: pointer;

      &:hover {
        color: variables.$base_color;
        transition: 0.3s;
        background-color: variables.$focus_color;

        i {
          color: #fff;
        }
      }
    }

    i {
      color: variables.$attenuated_color;
    }
  }

  input {
    width: 60%;
    font-size: 1.5rem;
    padding: 0.5rem;
    border: variables.$base_border;
    border-radius: 10px;

    &::placeholder {
      color: variables.$attenuated_color;
    }
  }
}
