@use "/src/variables";

@keyframes openAnimation {
  0% {
    top: -50%;
  }
  100% {
    top: 52%;
  }
}

@keyframes openAnimationMobile {
  0% {
    top: -100vh;
  }
  100% {
    top: 0;
  }
}

.CategoryForm {
  position: absolute;
  width: 60%;
  max-width: fit-content;
  top: 52%;
  left: 50%;
  padding: 2rem;
  transform: translate(-50%, -55%);
  border-radius: 25px;
  background-color: variables.$overlay_background;
  box-shadow: 0 5px 100px rgb(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: openAnimation 0.3s;

  @media screen and (max-width: 900px) {
    box-sizing: border-box;
    position: fixed;
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    transform: none;
    z-index: 2;
    animation: openAnimationMobile 0.3s;
    border-radius: 0;
    padding: 2rem 1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 900px) {
      height: 100%;
      justify-content: space-between;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 900px) {
      flex: 1;
      justify-content: center;
    }

    input:first-child {
      width: 95%;
      font-size: 1.8rem;
      color: variables.$base_color;
      border-bottom: variables.$base_border;
    }

    input:nth-child(3) {
      margin-top: 1rem;
      border: none;
      background: none;
      border-radius: 50%;
      -webkit-appearance: none;
      width: 60px;
      height: 60px;
      cursor: pointer;
      position: relative;

      &::-webkit-color-swatch {
        border-radius: 50%;
        border: none;
        flex-shrink: 0;
        width: 60px !important;
        height: 60px !important;
        position: absolute;
        top: 0;
        left: 0;
      }

      &::-webkit-color-swatch-wrapper {
        width: 60px !important;
        height: 60px !important;
        border-radius: 50%;
        border: none;
      }
    }
  }

  button {
    width: 100%;

    @media screen and (max-width: 900px) {
      margin-top: 0rem;
    }
  }
}
