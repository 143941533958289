@use "../../variables";

.Dashboard {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 7rem;
  position: relative;
  overflow-y: hidden;

  @media screen and (max-width: 900px) {
    overflow-y: initial;
    margin-bottom: 2rem;
    min-height: fit-content;
    max-height: none;
  }

  > h2 {
    display: none;

    @media screen and (max-width: 900px) {
      display: block;
      padding-left: 1rem;
      font-size: 2rem;
    }
  }

  &__bottom {
    margin-top: 3rem;
    padding: 0 2rem;
    display: flex;
    height: 52%;

    @media screen and (max-width: 900px) {
      display: block;
      padding: 0 1rem;
    }
  }
}
